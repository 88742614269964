import Project from "../Components/Project";

const Portofolio = () => {
  return (
    <>
      <Project />
    </>
  );
};

export default Portofolio;
